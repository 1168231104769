import { ChatStore } from '@/features/chat/store'
import { AuthStore } from '../features/auth/store'
import { ToolsStore } from '../features/tools/toolsStore'
import { ToolStore } from '@/features/tools/toolStore'
import { OrganizationsStore } from '@/features/organizations/store'
import { KnowledgeBaseStore } from '@/features/knowledge-bases/store'
import { KeyBindingsStore } from '@/features/keybindings/store'

const rootStore: RootStore = {} as RootStore

export type RootStore = {
  tools: ToolsStore
  auth: AuthStore
  chat: ChatStore
  tool: ToolStore
  organizations: OrganizationsStore
  knowledgeBases: KnowledgeBaseStore
  keybindings: KeyBindingsStore
}

export interface Store {
  rootStore: RootStore
}

rootStore.tools = new ToolsStore(rootStore)
rootStore.auth = new AuthStore(rootStore)
rootStore.chat = new ChatStore(rootStore)
rootStore.tool = new ToolStore(rootStore)
rootStore.organizations = new OrganizationsStore(rootStore)
rootStore.knowledgeBases = new KnowledgeBaseStore(rootStore)
rootStore.keybindings = new KeyBindingsStore(rootStore)
export default rootStore
