import { observer } from 'mobx-react'
import cx from 'classnames'

interface SpinnerProps {
  isVisible?: boolean
}

const Spinner = ({ isVisible }: SpinnerProps) => {
  return (
    <div className={cx('spinner', { 'is-visible': isVisible })}>
      <div className="spinner-ring"></div>
    </div>
  )
}

export default observer(Spinner)
