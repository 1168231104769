import React, { createContext, useContext, useEffect, useState } from 'react'
import { ExportMessageDialog } from '@/ui/artifact/ExportMessageDialog'
import { useDialogRef } from '@/utils/dialog'

interface ExportMessageDialogContextType {
  openExportMessageDialog: (threadId: string, messageIdx: number) => void
  closeExportMessageDialog: () => void
}

const ExportMessageDialogContext = createContext<
  ExportMessageDialogContextType | undefined
>(undefined)

export const ExportMessageDialogProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // Store the threadId and messageIdx for the dialog
  const [exportMessageData, setExportMessageData] = useState<{
    threadId: string
    messageIdx: number
  } | null>(null)

  const {
    dialogRef: exportDialogRef,
    handleOpen: openDialog,
    handleClose: closeDialog,
  } = useDialogRef()

  // Set threadId and messageIdx
  const openExportMessageDialog = (threadId: string, messageIdx: number) => {
    setExportMessageData({ threadId, messageIdx })
  }

  // Opens the export message dialog when the data is set
  useEffect(() => {
    if (exportMessageData) {
      openDialog()
    }
  }, [exportMessageData])

  // Closes the export message dialog and resets the data
  const closeExportMessageDialog = () => {
    closeDialog()
    setExportMessageData(null)
  }

  return (
    <ExportMessageDialogContext.Provider
      value={{ openExportMessageDialog, closeExportMessageDialog }}
    >
      {children}

      {exportMessageData && (
        <ExportMessageDialog
          threadId={exportMessageData.threadId}
          messageIdx={exportMessageData.messageIdx}
          exportDialogRef={exportDialogRef}
          handleCloseExportDialog={closeExportMessageDialog}
        />
      )}
    </ExportMessageDialogContext.Provider>
  )
}

export const useExportMessageDialog = () => {
  const context = useContext(ExportMessageDialogContext)

  if (!context) {
    throw new Error(
      'useExportMessageDialog must be used within an ExportMessageDialogProvider',
    )
  }

  return context
}
