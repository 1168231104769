import rootStore, { RootStore } from '@/app/store'
import { makeAutoObservable, runInAction } from 'mobx'
import { runChat, runTool } from '../tools/api'
import { MessageContentItem, Tool } from '../tools/types'
import { trackEvent } from '../tracking/api'

type SendMessageOptions = {
  message: string
  model?: string
  files?: File[]
  systemMessageBuilder?: string
  expectStream?: boolean
}

export class ToolStore {
  rootStore: RootStore
  data?: Tool
  inputs: any = {}
  generating = false
  sendingMessage = false
  awaitingFirstToken = false
  generationAbortController: AbortController | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  // NOTE: Called on logout
  public resetState() {
    this.maybeCancelGeneration()
    this.data = undefined
    this.inputs = {}
    this.generating = false
    this.sendingMessage = false
  }

  public setTool(tool: Tool) {
    this.data = tool
  }

  public setInputs(inputs: any) {
    this.inputs = inputs
  }

  public clearTool() {
    this.maybeCancelGeneration()
    this.data = undefined
    this.inputs = {}
  }

  trackToolGeneration() {
    const { chat } = rootStore

    if (chat.thread._id) {
      trackEvent({
        key: 'form_tool_re_generation',
        data: {
          toolId: this.data!.id,
          threadId: chat.thread._id,
        },
      })
    } else {
      trackEvent({
        key: 'form_tool_generation',
        data: {
          toolId: this.data!.id,
        },
      })
    }
  }

  async generate(expectStream = true, isDemo = false) {
    this.generating = true
    const { chat } = rootStore

    // NOTE: Track before clearing the thread
    this.trackToolGeneration()

    await chat.clearThread()

    if (!isDemo) {
      try {
        await chat.maybeCreateThread(this.data!.id)
      } catch (error) {
        return
      }
    }

    this.maybeCancelGeneration()
    this.generationAbortController = new AbortController()

    try {
      const result = await runTool({
        tool: this.data!,
        signal: this.generationAbortController.signal,
        inputs: this.inputs,
        threadId: chat.thread._id,
        isDemo,
      })

      if (expectStream) {
        await chat.ingestMessageStream(result.body!)
      }
    } finally {
      runInAction(() => {
        this.generating = false
      })
    }
  }

  async sendMessage({
    message,
    model,
    files,
    systemMessageBuilder,
    expectStream = true,
  }: SendMessageOptions) {
    this.generating = true
    const { chat } = rootStore
    chat.setSuggestions([])

    try {
      await chat.maybeCreateThread(this.data!.id)
    } catch (error) {
      return
    }

    const userMessageContent: MessageContentItem[] = [
      { type: 'text', text: message },
    ]

    if (files && files.length > 0) {
      for (const file of files) {
        userMessageContent.unshift({
          type: 'file',
          filename: file.name,
        })
      }
    }

    runInAction(() => {
      this.awaitingFirstToken = true
    })
    chat.addMessage({
      role: 'user',
      content: userMessageContent,
    })

    this.maybeCancelGeneration()
    this.generationAbortController = new AbortController()

    try {
      const response = await runChat({
        id: this.data!.id,
        prompt: message,
        model,
        signal: this.generationAbortController.signal,
        threadId: chat.thread._id,
        systemMessageBuilder: systemMessageBuilder,
        files,
      })

      if (expectStream) {
        await chat.ingestMessageStream(response.body!, (_event) => {
          runInAction(() => {
            this.awaitingFirstToken = false
          })
        })
      }
    } finally {
      runInAction(() => {
        this.generating = false
      })
    }
  }

  public maybeCancelGeneration() {
    if (this.generationAbortController) {
      this.generationAbortController.abort()
    }
  }
}
