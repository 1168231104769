import { RefObject, useEffect, useRef } from 'react'

export const useDialogRef = () => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  const handleOpen = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal()
    }
  }

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.close()
    }
  }

  return { dialogRef, handleOpen, handleClose }
}

export const useListenDialogOpen = (
  dialogRef: RefObject<HTMLDialogElement>,
  callback: () => void,
) => {
  useEffect(() => {
    if (!dialogRef?.current) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'open' && dialogRef.current?.open) {
          callback()
        }
      })
    })

    observer.observe(dialogRef.current, { attributes: true })

    return () => observer.disconnect()
  }, [dialogRef])
}
