import rootStore from '@/app/store'
import React from 'react'
import cx from 'classnames'
import { truncate } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import { Icon, Icons } from '@/ui/icon'

const MENU_LINKS = {
  organization: {
    href: '/subscriptions',
    icon: Icons.Group,
    label: 'Organización',
  },
  stats: {
    href: '/stats',
    icon: Icons.Stats,
    label: 'Estadísticas',
  },
  api: {
    href: '/api',
    icon: Icons.Code,
    label: 'API',
  },
  documents: {
    href: '/knowledge-bases/global',
    icon: Icons.Archive,
    label: 'Documentos AiKit',
  },
  history: {
    href: '/history',
    icon: Icons.History,
    label: 'Historial',
  },
  support: {
    href: '/support',
    icon: Icons.Help,
    label: 'Soporte',
  },
} as const

type MenuLinkKey = keyof typeof MENU_LINKS
type UserRole = 'admin' | 'org-manager' | 'user'

const ROLE_LINKS: Record<UserRole, MenuLinkKey[]> = {
  admin: ['documents', 'history', 'support'],
  'org-manager': ['organization', 'stats', 'history', 'support'],
  user: ['history', 'support'],
} as const

const isValidUserRole = (role: string): role is UserRole => {
  return ['admin', 'org-manager', 'user'].includes(role)
}

const getUserRole = (role: string): UserRole => {
  if (isValidUserRole(role)) {
    return role
  }
  return 'user'
}

const UserMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const menuRef = React.useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const { auth, tools, organizations, knowledgeBases, chat, tool } = rootStore

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClose = () => {
    setIsClosing(true)

    setTimeout(() => {
      setIsOpen(false)
      setIsClosing(false)
    }, 150)
  }

  const handleLogout = () => {
    auth.logout().then(() => {
      tools.resetState()
      chat.resetState()
      tool.resetState()
      organizations.resetState()
      knowledgeBases.resetState()

      navigate('/home')

      handleClose()
    })
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleClose()
      }
    }

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [isOpen])

  const userRole = getUserRole(auth.user.role)

  const navAndClose = (path: string) => {
    navigate(path)
    handleClose()
  }

  return (
    <div className="userMenu">
      <button
        className="userMenu-button"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <div aria-hidden="true" className="userMenu-avatar">
          <Icon size={28} name={Icons.User} />
        </div>
        <span className="userMenu-name">
          {truncate(auth.user.email.split('@')[0], 16).toLocaleLowerCase()}
        </span>
        <Icon
          className={cx('userMenu-icon', isOpen && 'is-open')}
          size={14}
          name={Icons.ChevronDown}
        />
      </button>

      <div
        ref={menuRef}
        className={cx(
          'userMenu-wrapper',
          isOpen && 'is-open',
          isClosing && 'is-closing',
        )}
        aria-hidden={!isOpen}
      >
        {isOpen ? (
          <ul className="userMenu-list">
            {/* {auth.user.active ? (
                <li>Creditos: {auth.user.chatTokensLeft}</li>
            ) : null} */}

            {ROLE_LINKS[userRole].map((linkKey: MenuLinkKey) => (
              <li key={linkKey}>
                <NavLink
                  href={MENU_LINKS[linkKey].href}
                  icon={MENU_LINKS[linkKey].icon}
                  onNavigate={() => navAndClose(MENU_LINKS[linkKey].href)}
                >
                  {MENU_LINKS[linkKey].label}
                </NavLink>
              </li>
            ))}
            <li>
              <button
                className="userMenu-link"
                onClick={handleLogout}
                role="menuitem"
              >
                <Icon size={14} name={Icons.Logout} />
                Cerrar sesión
              </button>
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export default UserMenu

interface NavLinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  icon: string
  href: string
  children: React.ReactNode
  onNavigate: () => void
}

const NavLink: React.FC<NavLinkProps> = ({
  icon,
  href,
  children,
  onNavigate,
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onNavigate()
  }

  return (
    <a
      className="userMenu-link"
      href={href}
      onClick={handleClick}
      role="menuitem"
      {...rest}
    >
      <Icon size={14} name={icon} />
      <span>{children}</span>
    </a>
  )
}
