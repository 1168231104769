import { useEffect, useReducer } from 'react'
import { checkGoogleOAuthStatus } from '@/features/auth/api'
import { GoogleOAuthUrlResponse } from '@/features/auth/api'
import { openTabAndFocus } from '@/utils/window'
import { exportToGdocs } from '@/features/tools/api'
import { toast } from 'react-toastify'
import ExportButton from '@/ui/ExportButton'
import logoDrive from '/images/logos/drive.svg'
type ExportState =
  | { status: 'idle' }
  | { status: 'checking' }
  | { status: 'connect-account'; oAuthUrl: string }
  | { status: 'generate-doc' }
  | { status: 'generating' }
  | { status: 'open'; documentUrl: string }

type ExportAction =
  | { type: 'RESET' }
  | { type: 'START_CHECK' }
  | { type: 'SET_OAUTH_STATUS'; response: GoogleOAuthUrlResponse }
  | { type: 'START_GENERATION' }
  | { type: 'STOP_GENERATION' }
  | { type: 'SET_DOCUMENT'; url: string }

function exportReducer(state: ExportState, action: ExportAction): ExportState {
  switch (action.type) {
    case 'RESET':
      return { status: 'idle' }

    case 'START_CHECK':
      return { status: 'checking' }

    case 'SET_OAUTH_STATUS':
      return action.response.authorized
        ? { status: 'generate-doc' }
        : { status: 'connect-account', oAuthUrl: action.response.url }

    case 'START_GENERATION':
      return { status: 'generating' }

    case 'STOP_GENERATION':
      return { status: 'generate-doc' }

    case 'SET_DOCUMENT':
      return { status: 'open', documentUrl: action.url }

    default:
      return state
  }
}

export const GDocsExportButton = ({
  threadId,
  messageIdx,
}: {
  threadId: string
  messageIdx: number
}) => {
  const [state, dispatch] = useReducer(exportReducer, { status: 'idle' })

  useEffect(() => {
    const checkOAuthStatus = async () => {
      dispatch({ type: 'START_CHECK' })

      try {
        const response = await checkGoogleOAuthStatus()
        dispatch({ type: 'SET_OAUTH_STATUS', response })
      } catch (error) {
        // Shallow
      }
    }

    checkOAuthStatus()

    const handleFocus = () => {
      checkOAuthStatus()
    }

    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [])

  const handleClick = async () => {
    switch (state.status) {
      case 'connect-account': {
        openTabAndFocus(state.oAuthUrl)
        break
      }

      case 'generate-doc': {
        dispatch({ type: 'START_GENERATION' })

        try {
          const documentUrl = await exportToGdocs(threadId, messageIdx)
          dispatch({ type: 'SET_DOCUMENT', url: documentUrl })
        } catch (error) {
          toast.error('Error generando documento en Google Docs.')
          dispatch({ type: 'STOP_GENERATION' })
        }
        break
      }

      case 'open': {
        openTabAndFocus(state.documentUrl)
        break
      }
    }
  }

  return (
    <ExportButton
      isReady={state.status === 'open'}
      isLoading={state.status === 'generating'}
      logo={logoDrive}
      text="Exportar a Google Docs"
      caption={
        state.status === 'connect-account'
          ? 'Solicitaremos conectar tu cuenta de Google.'
          : undefined
      }
      onClick={handleClick}
    />
  )
}
