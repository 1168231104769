import { observer } from 'mobx-react'
import { Icons, Icon } from '../icon'
import React, { forwardRef } from 'react'
import cx from 'classnames'
import RoundButton from '../RoundButton'

interface DialogProps {
  isRow?: boolean
  icon?: string
  title: string
  text?: string
  children?: React.ReactNode
  actions?: React.ReactNode
  onClose: () => void
}

const Dialog = forwardRef<HTMLDialogElement, Omit<DialogProps, 'ref'>>(
  (props, ref) => {
    const { isRow, icon, title, text, children, actions, onClose } = props

    const handleClose = () => {
      const dialogElement = ref as React.RefObject<HTMLDialogElement>
      if (dialogElement.current) {
        dialogElement.current.classList.add('closing')
        setTimeout(() => {
          dialogElement.current?.classList.remove('closing')
          onClose()
        }, 250)
      }
    }

    const handleClickOutside = (event: React.MouseEvent) => {
      const dialogElement = ref as React.RefObject<HTMLDialogElement>
      if (event.target === dialogElement.current) {
        handleClose()
      }
    }

    return (
      <dialog
        ref={ref}
        className="dialog"
        onKeyDown={(event) => event.key === 'Escape' && handleClose()}
        onClick={handleClickOutside}
      >
        <div className="dialog-wrapper">
          <header className="dialog-header">
            <RoundButton
              icon={Icons.Cross}
              className="dialog-close"
              label="Cerrar cuadro de diálogo"
              onClick={handleClose}
            />
            <h2 className="dialog-title">
              {icon ? <Icon size={24} name={icon} /> : null}
              {title}
            </h2>
            {text ? <p className="dialog-text">{text}</p> : null}
          </header>
          {children ? (
            <div
              className={cx('dialog-content', {
                'is-row': isRow,
              })}
            >
              {children}
            </div>
          ) : null}
          {actions ? (
            <footer className="dialog-footer">{actions}</footer>
          ) : null}
        </div>
      </dialog>
    )
  },
)

Dialog.displayName = 'Dialog'

export default observer(Dialog)
