import React, { ReactNode } from 'react'
import { motion } from 'framer-motion'

type Props = {
  hasFixHeight?: boolean
  children: ReactNode
}
const variants = {
  hidden: { opacity: 0, y: 4 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 4 },
}

const TransitionLayout = ({
  hasFixHeight,
  children,
}: Props): React.JSX.Element => {
  const [isAnimating, setIsAnimating] = React.useState(false)

  return (
    <motion.div
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.3, type: 'easeInOut' }}
      onAnimationStart={() => setIsAnimating(true)}
      onAnimationComplete={() => setIsAnimating(false)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minBlockSize: '100%',
        height: hasFixHeight ? '100%' : 'auto',
        overflow: isAnimating ? 'hidden' : 'initial',
      }}
    >
      {children}
    </motion.div>
  )
}

export default TransitionLayout
