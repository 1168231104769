import ReactGA from 'react-ga4'

export function sendPageView(path: string, title: string) {
  try {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title: title,
    })
  } catch (error) {
    console.warn('Failed to send page view', error)
  }
}

export function sendEvent(event: any) {
  try {
    ReactGA.event(event)
  } catch (error) {
    console.warn('Failed to send event', error)
  }
}

export function setUserId(userId: string) {
  try {
    ReactGA.set({ userId })
  } catch (error) {
    console.warn('Failed to set user ID', error)
  }
}
