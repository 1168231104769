import { RootStore } from '@/app/store'
import { makeAutoObservable } from 'mobx'

export interface KeyBindingRecord {
  id: number
  keys: string
  callback: (event: KeyboardEvent) => void
  description?: string
}

export class KeyBindingsStore {
  rootStore: RootStore
  keyBindingsMap: Map<string, KeyBindingRecord[]> = new Map()
  idCounter: number = 0
  boundHandleKeyDown: (ev: KeyboardEvent) => void

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
    this.boundHandleKeyDown = this.handleKeyDown.bind(this)
    document.addEventListener('keydown', this.boundHandleKeyDown)
  }

  normalizeKeyCombo(keyCombo: string): string {
    const parts = keyCombo.split('+').map((part) => part.trim().toLowerCase())
    const modifiers: string[] = []
    let key: string | null = null
    parts.forEach((part) => {
      if (
        part === 'ctrl' ||
        part === 'shift' ||
        part === 'alt' ||
        part === 'meta'
      ) {
        modifiers.push(part)
      } else {
        key = part
      }
    })
    modifiers.sort()
    if (key) {
      modifiers.push(key)
    }
    return modifiers.join('+')
  }

  register(
    record: Omit<KeyBindingRecord, 'id' | 'keys'> & { keys: string | string[] },
  ): number | number[] {
    const keys = Array.isArray(record.keys) ? record.keys : [record.keys]
    const ids: number[] = []
    for (const key of keys) {
      const normalizedKey = this.normalizeKeyCombo(key)
      this.idCounter++
      const newRecord: KeyBindingRecord = {
        ...record,
        keys: normalizedKey,
        id: this.idCounter,
      }
      if (!this.keyBindingsMap.has(normalizedKey)) {
        this.keyBindingsMap.set(normalizedKey, [])
      }
      this.keyBindingsMap.get(normalizedKey)!.push(newRecord)
      ids.push(newRecord.id)
    }
    return ids.length === 1 ? ids[0] : ids
  }

  unregister(id: number | number[]): void {
    const ids = Array.isArray(id) ? id : [id]
    for (const currentId of ids) {
      for (const [key, records] of this.keyBindingsMap.entries()) {
        const index = records.findIndex((r) => r.id === currentId)
        if (index !== -1) {
          records.splice(index, 1)
          if (records.length === 0) {
            this.keyBindingsMap.delete(key)
          }
          break
        }
      }
    }
  }

  getActiveBinding(keyCombo: string): KeyBindingRecord | undefined {
    const normalized = this.normalizeKeyCombo(keyCombo)
    const records = this.keyBindingsMap.get(normalized)
    if (records && records.length > 0) {
      return records[records.length - 1]
    }
    return undefined
  }

  normalizeEvent(event: KeyboardEvent): string {
    const modifiers: string[] = []
    if (event.ctrlKey) modifiers.push('ctrl')
    if (event.altKey) modifiers.push('alt')
    if (event.metaKey) modifiers.push('meta')
    if (event.shiftKey) modifiers.push('shift')
    modifiers.sort()

    if (event.key === undefined) {
      return ''
    }

    const key = event.key.toLowerCase()
    modifiers.push(key)
    return modifiers.join('+')
  }

  handleKeyDown(event: KeyboardEvent): void {
    const normalized = this.normalizeEvent(event)
    const activeBinding = this.getActiveBinding(normalized)
    if (activeBinding) {
      activeBinding.callback(event)
      event.preventDefault()
    }
  }

  serialize(): string {
    const result: { [key: string]: string } = {}
    for (const [key, records] of this.keyBindingsMap.entries()) {
      if (records.length > 0) {
        result[key] =
          records[records.length - 1].description || 'no description'
      }
    }
    return JSON.stringify(result, null, 2)
  }

  dispose(): void {
    document.removeEventListener('keydown', this.boundHandleKeyDown)
  }
}
