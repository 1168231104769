// markdownBuffer.ts

export enum ParseState {
  NORMAL = 'NORMAL',
  SQUARE_OPEN = 'SQUARE_OPEN', // reading text after [, until we see ]
  AWAIT_PAREN = 'AWAIT_PAREN', // we saw ], waiting to see if next char is (
  LINK = 'LINK', // [ ... ]( ... )
  CODE_BLOCK = 'CODE_BLOCK', // e.g. triple backticks
  INLINE_CODE = 'INLINE_CODE', // e.g. single backticks
  STRONG = 'STRONG', // **...**
  EMPHASIS = 'EMPHASIS', // *...*
  HTML_LINK = 'HTML_LINK', // <a ...> ... </a>
  BULLET_LIST = 'BULLET_LIST', // Bullet list item, e.g. "- item"
  NUMBERED_LIST = 'NUMBERED_LIST', // Numbered list item, e.g. "1. item"
}

/**
 * This class accumulates normal text, code blocks, bold, italic, placeholders,
 * and now also handles HTML links as well as bullet and numbered lists.
 */
export class MarkdownBuffer {
  private state: ParseState = ParseState.NORMAL

  /** Accumulated normal text when in NORMAL state. */
  private normalTextBuffer = ''

  /** For the [ ... ] portion of a potential link or placeholder. */
  private squareBuffer = ''

  /** For the entire [ ... ]( ... ) link. */
  private linkBuffer = ''

  /** For triple backticks, single backticks, bold, italic. */
  private elementBuffer = ''

  /** For partial tokens at chunk boundaries. */
  private leftover = ''

  /**
   * We’ll store HTML link text here once we detect <a ...>,
   * until we see </a>.
   */
  private htmlLinkBuffer = ''

  processText(chunk: string): string[] {
    if (!chunk || typeof chunk !== 'string') return []

    // Combine leftover from previous chunk.
    let text = this.leftover + chunk
    this.leftover = ''

    if (typeof text !== 'string') return []

    const output: string[] = []
    let i = 0

    while (i < text.length) {
      const slice = text.slice(i)

      // =======================
      // NORMAL STATE
      // =======================
      if (this.state === ParseState.NORMAL) {
        // --- NEW: Check for list markers at the start of a line ---
        if (i === 0 || text[i - 1] === '\n') {
          // Check for a numbered list marker: one or more digits followed by ". "
          let j = i
          let numStr = ''
          while (j < text.length && isDigit(text[j])) {
            numStr += text[j]
            j++
          }
          if (numStr.length > 0 && text[j] === '.' && text[j + 1] === ' ') {
            if (this.normalTextBuffer) {
              output.push(this.normalTextBuffer)
              this.normalTextBuffer = ''
            }
            this.state = ParseState.NUMBERED_LIST
            // Capture the entire marker (e.g. "12. ")
            this.elementBuffer = text.slice(i, j + 2)
            i = j + 2
            continue
          }
          // Check for a bullet list marker: "- ", "* ", or "+ "
          if (
            slice.startsWith('- ') ||
            slice.startsWith('* ') ||
            slice.startsWith('+ ')
          ) {
            if (this.normalTextBuffer) {
              output.push(this.normalTextBuffer)
              this.normalTextBuffer = ''
            }
            this.state = ParseState.BULLET_LIST
            // Capture the marker (2 characters, e.g. "- ")
            this.elementBuffer = slice.slice(0, 2)
            i += 2
            continue
          }
        }
        // ---------------- End list marker check ----------------

        // 1) DETECT CODE BLOCK
        if (slice.startsWith('```')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.CODE_BLOCK
          this.elementBuffer = '```'
          i += 3
        }
        // 2) DETECT INLINE CODE
        else if (slice.startsWith('`')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.INLINE_CODE
          this.elementBuffer = '`'
          i += 1
        }
        // 3) DETECT BOLD
        else if (slice.startsWith('**')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.STRONG
          this.elementBuffer = '**'
          i += 2
        }
        // 4) DETECT EMPHASIS
        // (Only treat a leading "*" as emphasis if it’s not followed by a space –
        // if it is "* " at the start of a line, that was already caught as a bullet list.)
        else if (slice.startsWith('*')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.EMPHASIS
          this.elementBuffer = '*'
          i += 1
        }
        // 5) DETECT POTENTIAL MARKDOWN LINK [
        else if (slice.startsWith('[')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.SQUARE_OPEN
          this.squareBuffer = '['
          i += 1
        }
        // 6) DETECT HTML LINK <a or <A (case-insensitive check)
        else if (slice.toLowerCase().startsWith('<a')) {
          if (this.normalTextBuffer) {
            output.push(this.normalTextBuffer)
            this.normalTextBuffer = ''
          }
          this.state = ParseState.HTML_LINK
          this.htmlLinkBuffer = ''
          // Note: from here on we accumulate into htmlLinkBuffer character-by-character.
        } else {
          // Normal text
          this.normalTextBuffer += text[i]
          i += 1
        }
      }
      // =======================
      // HTML_LINK STATE
      // =======================
      else if (this.state === ParseState.HTML_LINK) {
        this.htmlLinkBuffer += text[i]
        i += 1
        if (this.htmlLinkBuffer.toLowerCase().endsWith('</a>')) {
          output.push(this.htmlLinkBuffer)
          this.htmlLinkBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // SQUARE_OPEN STATE
      // =======================
      else if (this.state === ParseState.SQUARE_OPEN) {
        const ch = text[i]
        i += 1
        this.squareBuffer += ch
        if (ch === ']') {
          this.state = ParseState.AWAIT_PAREN
        }
      }
      // =======================
      // AWAIT_PAREN STATE
      // =======================
      else if (this.state === ParseState.AWAIT_PAREN) {
        if (i >= text.length) {
          break
        }
        const ch = text[i]
        if (ch === '(') {
          this.linkBuffer = this.squareBuffer + '('
          this.squareBuffer = ''
          this.state = ParseState.LINK
          i += 1
        } else {
          output.push(this.squareBuffer)
          this.squareBuffer = ''
          this.state = ParseState.NORMAL
          // Do not consume ch; let NORMAL state handle it on next iteration.
        }
      }
      // =======================
      // LINK STATE
      // =======================
      else if (this.state === ParseState.LINK) {
        const ch = text[i]
        i += 1
        this.linkBuffer += ch
        if (ch === ')') {
          output.push(this.linkBuffer)
          this.linkBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // CODE_BLOCK STATE
      // =======================
      else if (this.state === ParseState.CODE_BLOCK) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('```')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // INLINE_CODE STATE
      // =======================
      else if (this.state === ParseState.INLINE_CODE) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('`')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // STRONG STATE
      // =======================
      else if (this.state === ParseState.STRONG) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('**')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // EMPHASIS STATE
      // =======================
      else if (this.state === ParseState.EMPHASIS) {
        this.elementBuffer += text[i]
        i += 1
        if (this.elementBuffer.endsWith('*')) {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // BULLET_LIST STATE
      // =======================
      else if (this.state === ParseState.BULLET_LIST) {
        const ch = text[i]
        this.elementBuffer += ch
        i++
        if (ch === '\n') {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
      // =======================
      // NUMBERED_LIST STATE
      // =======================
      else if (this.state === ParseState.NUMBERED_LIST) {
        const ch = text[i]
        this.elementBuffer += ch
        i++
        if (ch === '\n') {
          output.push(this.elementBuffer)
          this.elementBuffer = ''
          this.state = ParseState.NORMAL
        }
      }
    } // end while

    // ============== AFTER THE LOOP ==============
    if (i < text.length) {
      // Some characters remain unprocessed.
      this.leftover = text.slice(i)
    }

    // Handle leftover logic based on state
    if (this.state === ParseState.NORMAL) {
      let combined = this.normalTextBuffer
      this.normalTextBuffer = ''
      const leftoverLen = detectPartialTokenLength(combined)
      if (leftoverLen > 0) {
        const cutoff = combined.length - leftoverLen
        this.leftover += combined.slice(cutoff)
        combined = combined.slice(0, cutoff)
      }
      if (combined) {
        output.push(combined)
      }
    } else if (this.state === ParseState.SQUARE_OPEN) {
      this.leftover = this.squareBuffer + this.leftover
      this.squareBuffer = ''
    } else if (this.state === ParseState.AWAIT_PAREN) {
      this.leftover = this.squareBuffer + this.leftover
      this.squareBuffer = ''
    } else if (this.state === ParseState.LINK) {
      this.leftover = this.linkBuffer + this.leftover
      this.linkBuffer = ''
    } else if (this.state === ParseState.HTML_LINK) {
      this.leftover = this.htmlLinkBuffer + this.leftover
      this.htmlLinkBuffer = ''
    } else if (
      this.state === ParseState.CODE_BLOCK ||
      this.state === ParseState.INLINE_CODE ||
      this.state === ParseState.STRONG ||
      this.state === ParseState.EMPHASIS
    ) {
      // Keep elementBuffer intact.
    } else if (
      this.state === ParseState.BULLET_LIST ||
      this.state === ParseState.NUMBERED_LIST
    ) {
      // Keep elementBuffer intact; it will be flushed later.
    }

    return output.filter(Boolean)
  }

  /**
   * flushRemaining() is called when the entire stream is finished.
   * We output any partially accumulated data so the user at least sees it,
   * even if it wasn’t “closed” properly.
   */
  flushRemaining(): string {
    let finalText = ''

    // normal text
    finalText += this.normalTextBuffer
    this.normalTextBuffer = ''

    // leftover bracket stuff
    finalText += this.squareBuffer
    this.squareBuffer = ''

    // leftover link stuff
    finalText += this.linkBuffer
    this.linkBuffer = ''

    // leftover HTML link stuff
    finalText += this.htmlLinkBuffer
    this.htmlLinkBuffer = ''

    // code/bold/italics and list partial
    finalText += this.elementBuffer
    this.elementBuffer = ''

    // leftover partial tokens
    finalText += this.leftover
    this.leftover = ''

    // reset state
    this.state = ParseState.NORMAL
    return finalText
  }
}

/**
 * A helper to see if the last 1..3 chars might be the start
 * of a Markdown token like ```, **, or [.
 */
function detectPartialTokenLength(text: string): number {
  if (!text) return 0
  const last3 = text.slice(-3)

  if (last3 === '```') return 3
  if (last3.endsWith('**')) return 2
  if (last3.endsWith('``')) return 2
  if (last3.endsWith('<a')) return 2

  const lastChar = last3.slice(-1)
  if (['`', '*', '[', '<'].includes(lastChar)) {
    return 1
  }
  return 0
}

/**
 * A simple helper to determine if a character is a digit.
 */
function isDigit(char: string): boolean {
  return char >= '0' && char <= '9'
}
