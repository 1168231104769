import { Icons } from '../icon'
import { exportThreadMessage } from '@/features/tools/api'
import { RefObject } from 'react'
import CTA from '../CTA'
import ExportButton from '../ExportButton'
import logoPdf from '/images/logos/pdf.svg'
import logoWord from '/images/logos/word.svg'
import { GDocsExportButton } from './GDocsExportButton'
import Dialog from '../dialogs/Dialog'

type ExportMessageDialogProps = {
  threadId: string
  messageIdx: number

  exportDialogRef: RefObject<HTMLDialogElement>
  handleCloseExportDialog: () => void
}

export const ExportMessageDialog = ({
  threadId,
  messageIdx,
  exportDialogRef,
  handleCloseExportDialog,
}: ExportMessageDialogProps) => {
  const onExport = (format: 'pdf' | 'docx') => {
    return exportThreadMessage(threadId, messageIdx, format)
  }

  return (
    <Dialog
      isRow
      ref={exportDialogRef}
      icon={Icons.Export}
      title="Exportar"
      text="Escoge el formato en el que prefieres exportar el documento generado."
      onClose={handleCloseExportDialog}
      actions={
        <CTA variant="default" size="medium" onClick={handleCloseExportDialog}>
          Cancelar
        </CTA>
      }
    >
      <GDocsExportButton threadId={threadId} messageIdx={messageIdx} />
      <ExportButton
        logo={logoPdf}
        text="Exportar a PDF"
        onClick={() => onExport('pdf')}
      />
      <ExportButton
        logo={logoWord}
        text="Exportar a Word"
        onClick={() => onExport('docx')}
      />
    </Dialog>
  )
}
